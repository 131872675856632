class SectionTemplateDefault extends BATComponent {
	/* Lifecycle event callbacks */
	beforeLoad() {
		if (this.dataset.model) {
			// Padding top
			this.data.paddingTop =
				typeof this.data.paddingTop !== 'undefined'
					? this.data.paddingTop
					: '';

			// Padding bottom
			this.data.paddingBottom =
				this.data.paddingBottom === 'same-as-top'
					? this.data.paddingTop.replace('top', 'bottom')
					: this.data.paddingBottom;

			// Padding left
			this.data.paddingLeft =
				this.data.paddingLeft === 'same-as-top'
					? this.data.paddingTop.replace('top', 'left')
					: this.data.paddingLeft;

			// Padding right
			this.data.paddingRight =
				this.data.paddingRight === 'same-as-top'
					? this.data.paddingTop.replace('top', 'right')
					: this.data.paddingRight;

			// Background Image
			if (
				typeof this.data.backgroundImage.src !== 'undefined' &&
				this.data.backgroundImage.src
			) {
				this.style.backgroundImage = `url(${this.data.backgroundImage.src})`;

				if (this.data.alignBackground) {
					this.style.backgroundPosition = `${this.data.alignBackground} center`;
				}

				if (this.data.scale === 'fit') {
					this.style.backgroundSize = 'cover';
					this.style.backgroundRepeat = 'no-repeat';
				} else {
					this.style.backgroundSize = 'auto';
					this.style.backgroundRepeat = 'repeat';
				}
			}
		}
	}

	afterLoad() {
		this.$el = $(this);
	}

	beforeUpdate() {
		// console.log('SectionTemplateDefault before update');
	}

	afterUpdate() {
		// console.log('SectionTemplateDefault after update');
	}

	unload() {
		// console.log('SectionTemplateDefault after unload');
	}
}

!customElements.get('bat-section-default') &&
	customElements.define('bat-section-default', SectionTemplateDefault);
